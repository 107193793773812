/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useEffect } from 'react'
import Overlay from './overlay'
import Close from './close'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const Modal = (props) => {

  const { scrollToTop } = props

  useEffect(() => {
    if (!scrollToTop)
      return

    gsap.to(window, {duration: 0.3, scrollTo: 0});
  }, [scrollToTop])

  const containerSX = {
    position: 'absolute',
    top: '100px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: props.bleed ? '0px' : 4,
    zIndex: 1001,
    backgroundColor: props.variant === 'light' ? 'white' : 'transparent'
  }

  return (
    <Box sx={containerSX} className={props.className}>
      {props.children}
    </Box>
  )
}

export default Modal
export { Overlay, Close }