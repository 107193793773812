/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx, Flex, Box, Text, Button, AspectRatio } from 'theme-ui'
import { useMediaQuery } from 'react-responsive'
import { responsive } from '../../utils/helpers'
import { renderRichText } from '../../richtext'
import { noParagraphTags } from '../../richtext/renderers'

const LargePromo = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 940px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 630px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 940px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 941px)' })
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  const backgroundSX = {
    backgroundColor: props.light ? 'white' : 'transparent',
    pt: 17,
    pb: 17,
  }

  const containerSX = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 8
  }

  const headingSX = {
    fontFamily: 'light',
    // fontSize: responsive('50px', '61px'),
    fontSize: ['55px', '70px', null, null, null, '40px', '61px'],
    // fontSize: 'calc(50px + (60 - 50) * ((100vw - 320px) / (1500 - 320)))',
    lineHeight: '0.85em',

    'b': {
      fontFamily: 'heading'
    }
  }

  const imageSX = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: ['top right', 'bottom right', null, null, null, 'top right'],
  }

  const captionSX = {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    width: responsive('100%', '30%'),
    padding: 4,
    flexDirection: 'column',
    justifyContent: responsive('flex-start', 'center')
  }

  const buttonSX = {
    alignSelf: 'flex-start',
    marginTop: 2
  }

  const desktopImage = props.imageDesktop?.file?.url
  const mobileImage = props.imageMobile?.file?.url
  const desktopDark = props.imageDesktopDark ? props.imageDesktopDark.file.url : desktopImage
  const mobileDark = props.imageMobileDark ? props.imageMobileDark.file.url : mobileImage
  const imageSrc = isTabletOrMobile ? (props.dark ? mobileDark : mobileImage) : (props.dark ? desktopDark : desktopImage)
  
  const ratio = 
    isMobile 
      ? 323/700 
      : isTablet
        ? 585/1090
        : 1248/404

  return (
    <Box sx={backgroundSX}>
      <AspectRatio ratio={ratio} sx={containerSX} as="figure">
        <Box sx={containerSX}>
          <img sx={imageSX} src={imageSrc} alt="FGR App out now" />
          <Flex as="figcaption" sx={captionSX}>
            {props.title &&
              <Text as="p" sx={headingSX}>{renderRichText(props.title, false, {renderNode: noParagraphTags})}</Text>
            }
            {props.button &&
              <Button variant={props.button.colour === 'Dark' ? 'tertiary' : props.style === 'Light' ? 'primary' : 'tertiary'} sx={buttonSX} as="a" href={props.button.url}>{props.button.label}</Button>
            }
          </Flex>
        </Box>
      </AspectRatio>
    </Box>
  )
}

export default LargePromo