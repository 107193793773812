/** @jsx jsx */
import { jsx, Grid, Box, Flex, Text } from 'theme-ui';

const Single = (props) => {

  const total = props.left.amount + props.right.amount;
  const percentage1 = (props.left.amount / total) * 100
  const percentage2 = (props.right.amount / total) * 100

  const leftSX = {
    height: '7px',
    display: 'block',
    backgroundColor: props.left.color ? props.left.color : 'primary'
  }

  const rightSX = {
    height: '7px',
    display: 'block',
    backgroundColor: props.left.color ? props.left.color : 'black'
  }

  const labelSX = {
    fontFamily: 'body',
    fontSize: 4,
    fontWeight: 700
  }

  const labelContainerSX = {
    justifyContent: 'space-between',
    marginTop: 2,
  }

  const headingSX = {
    textAlign: 'center',
    marginBottom: 2
  }

  return (
    <Box mb={1}>
      {props.heading &&
        <Text sx={headingSX}>{props.heading}</Text>
      }
      
      <Grid columns={`${percentage1}% ${percentage2}%`} gap={'0px'}>
        <Box sx={leftSX}></Box>
        <Box sx={rightSX}></Box>
      </Grid>
      <Flex sx={labelContainerSX}>
        <Text sx={labelSX}>{props.left.label}</Text>
        <Text sx={labelSX}>{props.right.label}</Text>
      </Flex>
    </Box>
  )
}

export default Single