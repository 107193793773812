import { arrows } from '../carousel/style'
import { responsive } from '../../utils/helpers'

const style = {
  carousel: {
    mb: responsive(0, 7),
    ...arrows,
  },
  inline: {
    overflow: 'visible',
  },
  bold: {
    fontWeight: 700,
    color: 'black',
    textDecoration: 'none',
  }
}

export default style