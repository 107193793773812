/** @jsx jsx */
// import { useContext } from 'react'
import { jsx, Box } from 'theme-ui'
// import { HeaderContext } from '../header'

const Overlay = (props) => {

  // const headerContext = useContext(HeaderContext)

  const style = {
    position: 'fixed',
    // top: `${headerContext.height}px`,
    top: '0px',
    left: '0px',
    width: '100%',
    backgroundColor: 'black',
    zIndex: 1000,
    height: '100%',
    opacity: '0.95'
  }

  return (
    <Box sx={style} onClick={props.onClick} />
  )
}

export default Overlay