import axios from 'axios'

export const sendHubspotForm = async requestData => {
  return axios
    .post(
      `https://api.hsforms.com/submissions/v3/integration/submit/26094928/${requestData.formId}`,
      {
        fields: [...requestData.fields],
      }
    )
    .then(function (response) {
      return { success: true, data: response.data }
    })
    .catch(function (error) {
      return { success: false, data: error?.response }
    })
}
