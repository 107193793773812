/** @jsx jsx */
import { jsx, Card, Box, Heading, Text, Flex } from 'theme-ui'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText as render } from 'gatsby-source-contentful/rich-text'

import Accordian from '../components/accordion'
import Article, { Sponsor, SponsorName } from '../components/article'
import { LargePromo, SmallPromo, ShopPromo } from '../components/promo'
import {
  CompetitionForm,
  ComplaintsForm,
  EventsForm,
  FanBrigadesForm,
  GeneralEnquiriesForm,
} from '../components/contactForm'
import Gallery from '../components/gallery'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import Twitter from '../components/twitter'
import QuoteMark from '../images/quote-green.svg'
import { toString, hasCards } from './helpers'
import { VideoCard } from '../components/video'
import { CaptionCard } from '../components/card'
import { Stack } from 'raam'
import Table, { parseTableData } from '../components/table'
import Embellishment from '../components/embellishment'
import File from '../icons/file.svg'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

// Setting the rendering options. Same as:
// https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
const defaultNodeRenderers = (noLayout = false, aside = false) => {
  const quoteSX = {
    my: 5,
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontSize: '56px',

    '&:before, &:after': {
      content: '""',
      width: '42px',
      height: '24px',
      display: 'block',
      background: `url(${QuoteMark}) left top / 41px 24px no-repeat transparent`,
    },

    '&:before': {
      marginBottom: '15px',
    },

    '&:after': {
      marginTop: '15px',
      transform: 'rotate(180deg)',
    },
  }

  const paragraphSX = {
    lineHeight: 'body',

    a: {
      color: 'inherit',
    },
  }

  return {
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <Box
          as="ul"
          sx={{
            paddingLeft: '20px',

            li: {
              listStyleType: 'disc',
            },
          }}
        >
          {children}
        </Box>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const target = node.data.target
      if (!target) {
        console.log('NODE TARGET MISSING', node)
        return null
      }
      return (
        <GetComponent
          selector={target.__typename}
          data={target}
          noLayout={noLayout}
          aside={aside}
        />
      )
    },

    [BLOCKS.EMBEDDED_ASSET]: node => {
      const target = node.data.target
      if (!target) {
        console.log('ASSET TARGET MISSING', node)
        return null
      }
      switch (target.file.contentType) {
        case 'image/png':
        case 'image/jpeg':
          return (
            <img
              src={node.data.target.file.url}
              alt={node.data.target.title}
              sx={{ width: '100%' }}
            />
          )
        default:
          return (
            <Flex
              sx={{
                alignItems: 'center',
                color: 'inherit',
                textDecoration: 'none',
              }}
              as="a"
              href={target.file.url}
              target="_blank"
            >
              <File />
              <Text pl={2} sx={{ fontWeight: 700 }}>
                {target.title}
                <Text sx={{ fontWeight: 400, display: 'inline', ml: '5px' }}>
                  (PDF)
                </Text>
              </Text>
            </Flex>
          )
      }
    },

    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading
        as="h2"
        sx={{
          fontFamily: 'heading',
          lineHeight: 'heading',
          fontSize: 9,
          color: 'black',
        }}
      >
        {children}
      </Heading>
    ),

    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading
        as="h3"
        sx={{
          fontFamily: 'heading',
          lineHeight: 'heading',
          fontSize: 8,
          color: 'black',
        }}
      >
        {children}
      </Heading>
    ),

    [BLOCKS.PARAGRAPH]: (node, children) => {
      // if (toString(node) === '')
      //   return null
      return (
        <Text as="p" sx={paragraphSX}>
          {children}
        </Text>
      )
    },

    [BLOCKS.QUOTE]: (node, children) => (
      <Text as="blockquote" sx={quoteSX}>
        {toString(node)}
      </Text>
    ),

    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const target = node?.data?.target
      const type = target?.__typename
      if (!type)
        return (
          <Text as="p" sx={paragraphSX}>
            {children}
          </Text>
        )
      const url =
        type === 'ContentfulPage'
          ? `/${target?.slug}`
          : type === 'ContentfulTeam'
          ? `/team/${target?.slug}`
          : type === 'ContentfulNews'
          ? `/news/${target?.slug}`
          : target?.slug

      return <Link to={url}>{children}</Link>
    },
  }
}

const defaultMarkRenderers = () => {
  return {
    [MARKS.BOLD]: text => <b sx={{ fontWeight: 700 }}>{text}</b>,
  }
}

export const GetComponent = ({ selector, data, noLayout, aside }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

  const wrapComponent = () => {
    if (noLayout) return false

    switch (selector) {
      case 'ContentfulPromo':
        switch (data.variant) {
          case 'Large':
          case 'Products':
            return true
          default:
            return false
        }
      case 'ContentfulFeed':
      case 'ContentfulTwitter':
      case 'ContentfulArticle':
        return true
      default:
        return false
    }
  }

  const renderComponent = () => {
    switch (selector) {
      case 'ContentfulPromo':
        const promoData = {
          imageDesktop: data.desktopImage,
          imageMobile: data.mobileImage,
          title: data.heading,
          category: data.category,
          style: data.style,
          button:
            data.buttonLabel && data.buttonUrl
              ? {
                  label: data.buttonLabel,
                  url: data.buttonUrl,
                  variant: data.buttonStyle,
                }
              : false,
        }
        switch (data.variant) {
          case 'Large':
            return <LargePromo {...promoData} />
          case 'Products':
            return <ShopPromo {...promoData} products={data.products} />
          default:
          case 'Small':
            return <SmallPromo {...promoData} fixedHeight />
        }
      case 'ContentfulFaQs':
        return (
          <Card variant="border">
            {data.items.map((x, i) => {
              return (
                <Accordian
                  key={i}
                  collapsable
                  heading={x.title}
                  variant="light"
                >
                  <Box p={4}>
                    <Stack gap={4}>{renderRichText(x.content)}</Stack>
                  </Box>
                </Accordian>
              )
            })}
          </Card>
        )
      case 'ContentfulNews':
        const newsData = {
          ...data,
          thumbnail: { url: data.thumbnail?.file?.url },
        }

        return data.video ? (
          <VideoCard featured={true} {...newsData} height={false} />
        ) : (
          <CaptionCard
            featured={aside ? false : isMobile ? false : true}
            {...newsData}
            url={`/news/${data.slug}`}
            height={false}
          />
        )
      case 'ContentfulArticle':
        return <Article {...data} />
      case 'ContentfulCard':
        return (
          <Card variant="slim">
            <Accordian
              collapsable={data.collapsable}
              heading={data.cardHeading}
              open={!data.collapsable}
            >
              <Box p={4} sx={{ p: { mb: 2, fontSize: 1 } }}>
                {renderRichText(data.content)}
              </Box>
            </Accordian>
          </Card>
        )
      case 'ContentfulContactForm':
        return data.formType === 'Complaints' ? (
          <ComplaintsForm
            main={true}
            heading={data.contactFormHeading}
            text={renderRichText(data.content)}
          />
        ) : data.formType === 'Competition' ? (
          <CompetitionForm
            main={true}
            heading={data.contactFormHeading}
            text={renderRichText(data.content)}
          />
        ) : data.formType === 'Events' ? (
          <EventsForm
            main={true}
            heading={data.contactFormHeading}
            text={renderRichText(data.content)}
          />
        ) : data.formType === 'Fan Brigades' ? (
          <FanBrigadesForm
            main={true}
            heading={data.contactFormHeading}
            text={renderRichText(data.content)}
          />
        ) : (
          <GeneralEnquiriesForm
            main={true}
            heading={data.contactFormHeading}
            text={renderRichText(data.content)}
          />
        )
      case 'ContentfulGallery':
        return <Gallery {...data} showHeader />
      case 'ContentfulTwitterFeed':
        return <Twitter />
      case 'ContentfulSponsor':
        return (
          <Sponsor>
            {data.logo && (
              <img
                sx={{ width: '100%' }}
                src={data.logo.file.url}
                alt={data.name}
              />
            )}
            {!data.logo && <SponsorName>{data.title}</SponsorName>}
          </Sponsor>
        )
      case 'ContentfulTable':
        const parsedTableData = parseTableData(data?.data?.tableData)
        const tableData = parsedTableData ? parsedTableData : {}
        return <Table {...tableData} />
      case 'ContentfulEmbellishment':
        return <Embellishment {...data} />
      default:
        return null
    }
  }

  if (wrapComponent()) {
    return (
      <Wrapper>
        <Inner>{renderComponent()}</Inner>
      </Wrapper>
    )
  }

  return renderComponent()
}

export const renderRichText = (
  document,
  noLayout = false,
  options = {},
  aside = false
) => {
  try {
    return render(document, {
      renderNode: {
        ...defaultNodeRenderers(noLayout, aside),
        ...options.renderNode,
      },
      renderMark: {
        ...defaultMarkRenderers(),
        ...options.renderMark,
      },
    })
  } catch (e) {
    console.log('Rich text render error', e)
    console.log('DOCUMENT', document)
    console.log('RAW', JSON.parse(document.raw))
    return null
  }
}

export { hasCards }
