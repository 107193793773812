/** @jsx jsx */
import React from 'react'
import { jsx, Grid, Box, Card } from 'theme-ui';
import ArticleLayout from './layout'
import Main from './main'
import Sidebar from './sidebar'
import Sponsor, { SponsorName } from './sponsor'
import { responsive } from '../../utils/helpers'
import { renderRichText, hasCards } from '../../richtext'
import Versus from '../versus'
import Statistic from '../statistic'
import { Stack } from 'raam'
import Byline from './byline'
import Accordion from '../accordion'
import LineUp from '../lineup'
import NextMatch from '../nextMatch'

const Article = (props) => {

  const {main, aside} = props

  const asideSX = {
    gridRow: props.reverse ? responsive(1, 'auto') : 'auto'
  }

  return (
    <Grid 
      columns={props.columns ? props.columns : responsive(1, '70% 1fr')} 
      gap={hasCards(main) || hasCards(aside) ? 4 : 15}
      ref={props.forwardedGridRef}
      className={props.className}
    >
      {main &&
        <Stack ref={props.forwardedMainRef}>
          {renderRichText(main, true)}
          {props.mainExtra}
        </Stack>
      }
      {(aside || props.forceAside) &&
        <Stack ref={props.forwardedAsideRef} sx={asideSX}>
          {props.asideBefore}
          {aside && renderRichText(aside, true, {}, true)}
          {props.optaFixtureId &&
            <React.Fragment>
              <Card variant="slim">
                <Accordion heading='Line-ups' collapsable sx={{mb: '1px'}}>
                  <Box pt={3} pb={3} pl={2} pr={2}>
                    <LineUp optaFixtureId={props.optaFixtureId} />
                  </Box>
                </Accordion>
                <Accordion heading='Statistics' collapsable sx={{mb: '1px'}} >
                  <Box pt={3} pb={3} pl={8} pr={8}>
                    <Versus 
                      variant="tiny" 
                      color="black"
                      optaFixtureId={props.optaFixtureId}
                      sx={{mb: 4}}
                    />
                    <Statistic optaFixtureId={props.optaFixtureId} />
                  </Box>
                </Accordion>
                <Accordion heading='Next Match' collapsable>
                  <Box pt={3} pb={3} pl={8} pr={8}>
                    <NextMatch 
                      sx={{mb: 4}} 
                      tickets
                      // travel
                      live
                      variant="small" 
                      color="black"
                    />
                  </Box>
                </Accordion>
              </Card>
            </React.Fragment>
          }
          {props.asideExtra}
        </Stack>
      }
      
    </Grid>
  )
}

export default Article
export { ArticleLayout, Main, Sidebar, Sponsor, SponsorName, Byline}