const styles = {
  card: {
    background: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    padding: [0, 0],
  },
  form: {
    display: 'grid',
    gap: '12px',
  },
  heading: {
    fontFamily: 'heading',
    lineHeight: 'heading',
    color: 'black',
  },
  text: {
    mt: [2, null, null, null, null, null, null, 4],
    mb: [2, null, null, null, null, null, null, 4],
    lineHeight: 'body',

    a: {
      color: 'black',
      fontWeight: '700',
    },
  },
  input: {
    bg: 'white',
    border: '2px solid',
    borderColor: 'background',
    borderRadius: 0,
    fontFamily: 'body',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    mb: 0,
  },
  radio: {
    height: '26px',
    pl: '34px',

    '.label': {
      fontSize: '14px',
      lineHeight: 'body',
    },
    '.checkmark': {
      height: '26px',
      width: '26px',
    },
    'input:checked ~ .checkmark:after': {
      height: '16px',
      width: '16px',
    },
  },
  termsText: {
    lineHeight: 'body',
    mt: '10px',

    a: {
      color: 'black',
      fontWeight: '700',
    },
  },
}

export default styles
