/** @jsx jsx */
import { useState } from 'react'
import {
  jsx,
  Card,
  Heading,
  Text,
  Box,
  Input,
  Button,
  Label,
  Select,
  Checkbox,
} from 'theme-ui'
import styles from './contactForm.styles'
import { Wrap } from 'raam'
import { Radio } from '../form'
import { sendHubspotForm } from '../../utils/hubspot'

const EventsForm = props => {
  const [cateringRequired, setCateringRequired] = useState(false)
  const [stayUpdated, setStayUpdated] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const eventTypeOptions = [
    'Meeting',
    'Away Day',
    'Conference',
    'Awards',
    'Party',
    'Other',
  ]

  const techOptions = [
    'PA',
    'Projector',
    'Video Calling',
    'Stage',
    'Wireless Microphone',
    'Technician',
    'Secure WiFi',
    'Other',
  ]

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const eventsForm = document.getElementById('events-form')
    const formData = new FormData(eventsForm)

    // Build hubspot fields
    let fields = []
    let techOptionFields = ''
    for (const [key, value] of formData) {
      if (techOptions.includes(key)) {
        techOptionFields = techOptionFields += `${key};`
      } else {
        fields = [
          ...fields,
          {
            objectTypeId: key === 'name' ? '0-2' : '0-1',
            name: key,
            value: value,
          },
        ]
      }
    }

    const requestData = {
      formId: '392ceb7b-e638-4baa-a5d7-b24810c3bb8e',
      fields: [
        ...fields,
        {
          objectTypeId: '0-1',
          name: 'tech_requirements',
          value: techOptionFields,
        },
      ],
    }

    const request = await sendHubspotForm(requestData)

    if (request.success) {
      setSubmitting(false)
      setSubmitted(true)
    }
  }

  const { main } = props

  return (
    <Card sx={main && styles.card}>
      <Heading
        as={main ? 'h2' : 'h3'}
        sx={{ ...styles.heading, fontSize: main ? 10 : 9 }}
      >
        {props.heading}
      </Heading>
      <Text sx={styles.text}>{props.text}</Text>
      <Box
        as="form"
        id="events-form"
        onSubmit={handleSubmit}
        sx={{
          ...styles.form,
          gridTemplateColumns: main ? ['1fr', 'repeat(2, 1fr)'] : '1fr',
        }}
      >
        <Input
          type="text"
          name="firstname"
          placeholder="First name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="lastname"
          placeholder="Last name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="email"
          name="email"
          placeholder="Enter email address"
          required={true}
          sx={styles.input}
        />
        <Input
          type="tel"
          name="phone"
          placeholder="Phone number"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="name"
          placeholder="Company"
          required={true}
          sx={styles.input}
        />
        <Input
          type="number"
          name="number_of_guests"
          placeholder="Number of guests"
          required={true}
          min={1}
          sx={styles.input}
        />
        <Box>
          <Select sx={styles.input} name="event_type" required={true}>
            <option value="">Select event type</option>
            {eventTypeOptions.map((item, i) => (
              <option key={i}>{item}</option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text
            as="p"
            sx={{
              gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
              mb: 1,
            }}
          >
            Will you require catering?
          </Text>
          <Wrap
            sx={{
              gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
            }}
          >
            <Label sx={styles.radioLabel}>
              <Radio
                name="catering"
                onChange={() => {
                  setCateringRequired(true)
                }}
                checked={cateringRequired}
                value="Yes"
                label="Yes"
                sx={styles.radio}
              />
            </Label>
            <Label sx={styles.radioLabel}>
              <Radio
                name="catering"
                onChange={() => {
                  setCateringRequired(false)
                }}
                value="No"
                checked={!cateringRequired}
                label="No"
                sx={styles.radio}
              />
            </Label>
          </Wrap>
        </Box>
        <Box sx={{ display: 'grid', gap: 1 }}>
          <Text
            as="p"
            sx={{
              gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
              mb: 1,
            }}
          >
            Tech requirements
          </Text>
          {techOptions.map((item, i) => (
            <Label key={i}>
              <Checkbox name={item} />
              {item}
            </Label>
          ))}
        </Box>
        <Text
          as="p"
          sx={{
            ...styles.termsText,
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          We want you to be the first to know about New Signings, Competitions,
          Club News, Ticket Availability and occasional offers from partners.
          You can change your preferences or unsubscribe at any time
        </Text>
        <Wrap
          sx={{
            justifyContent: ['center', null, null, null, null, 'flex-start'],
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          <Label sx={styles.radioLabel}>
            <Radio
              name="opt_in"
              onChange={() => {
                setStayUpdated(true)
              }}
              checked={stayUpdated}
              value="Yes"
              label="Keep me updated"
              sx={styles.radio}
            />
          </Label>
          <Label sx={styles.radioLabel}>
            <Radio
              name="opt_in"
              onChange={() => {
                setStayUpdated(false)
              }}
              value="No"
              checked={!stayUpdated}
              label="No thanks"
              sx={styles.radio}
            />
          </Label>
        </Wrap>
        <Button
          sx={{
            mt: '15px',
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
            maxWidth: main ? '230px' : '100%',
          }}
          type="submit"
          disabled={submitting || submitted}
        >
          {submitting ? 'Submitting' : submitted ? 'Thanks' : 'Submit'}
        </Button>
        <Text
          as="p"
          sx={{
            ...styles.termsText,
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          By clicking ‘Submit’ you agree to our 
          <a href="/policies" target="_blank">
            terms and conditions
          </a>
        </Text>
      </Box>
    </Card>
  )
}

export default EventsForm
