/** @jsx jsx */
import { useState } from 'react'
import {
  jsx,
  Card,
  Heading,
  Text,
  Box,
  Input,
  Button,
  Select,
  Textarea,
} from 'theme-ui'
import styles from './contactForm.styles'
import { sendHubspotForm } from '../../utils/hubspot'

const ComplaintsForm = props => {
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const complaintsForm = document.getElementById('complaints-form')
    const formData = new FormData(complaintsForm)

    const complaintFields = ['Date', 'Complaint Type', 'Details']

    // Build hubspot fields
    let fields = []
    let complaintInfo = ''
    for (const [key, value] of formData) {
      if (complaintFields.includes(key)) {
        complaintInfo = complaintInfo += `${key}: ${value}\n`
      } else {
        fields = [
          ...fields,
          {
            objectTypeId: '0-1',
            name: key,
            value: value,
          },
        ]
      }
    }

    const requestData = {
      formId: '0b6c4478-8311-47eb-ba9b-ead978c1df26',
      fields: [
        ...fields,
        {
          objectTypeId: '0-1',
          name: 'complains_info',
          value: complaintInfo,
        },
      ],
    }

    const request = await sendHubspotForm(requestData)

    if (request.success) {
      setSubmitting(false)
      setSubmitted(true)
    }
  }

  const { main } = props

  const complaintOptions = [
    'Match day',
    'Merchandise',
    'Events',
    'Ticketing',
    'Staff/Stewarding',
  ]

  return (
    <Card sx={main && styles.card}>
      <Heading
        as={main ? 'h2' : 'h3'}
        sx={{ ...styles.heading, fontSize: main ? 10 : 9 }}
      >
        {props.heading}
      </Heading>
      <Text sx={styles.text}>{props.text}</Text>
      <Box
        as="form"
        id="complaints-form"
        onSubmit={handleSubmit}
        sx={{
          ...styles.form,
          gridTemplateColumns: main ? ['1fr', 'repeat(2, 1fr)'] : '1fr',
        }}
      >
        <Input
          type="text"
          name="firstname"
          placeholder="First name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="lastname"
          placeholder="Last name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="email"
          name="email"
          placeholder="Enter email address"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="Date"
          placeholder="Date of Complaint/Issue"
          onFocus={e => (e.target.type = 'date')}
          required={true}
          sx={styles.input}
        />
        <Box>
          <Select sx={styles.input} name="Complaint Type" required={true}>
            <option value="">Select relation of complaint</option>
            {complaintOptions.map((item, i) => (
              <option key={i}>{item}</option>
            ))}
          </Select>
        </Box>
        <Input
          type="tel"
          name="phone"
          placeholder="Phone number"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="zip"
          placeholder="Postcode"
          required={true}
          sx={styles.input}
        />
        <Textarea
          name="Details"
          placeholder="Details of complaint"
          required={true}
          sx={styles.input}
        />
        <Button
          sx={{
            mt: '15px',
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
            maxWidth: main ? '230px' : '100%',
          }}
          type="submit"
          disabled={submitting || submitted}
        >
          {submitting ? 'Submitting' : submitted ? 'Thanks' : 'Submit'}
        </Button>
        <Text
          as="p"
          sx={{
            ...styles.termsText,
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          By clicking ‘Submit’ you agree to our 
          <a href="/policies" target="_blank">
            terms and conditions
          </a>
        </Text>
      </Box>
    </Card>
  )
}

export default ComplaintsForm
