/** @jsx jsx */
import { useState } from 'react'
import { jsx, Card, Heading, Text, Box, Input, Button, Label } from 'theme-ui'
import styles from './contactForm.styles'
import { Wrap } from 'raam'
import { Radio } from '../form'
import { sendHubspotForm } from '../../utils/hubspot'

const FanBrigadesForm = props => {
  const [stayUpdated, setStayUpdated] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const fanBrigadesForm = document.getElementById('fan-brigades-form')
    const formData = new FormData(fanBrigadesForm)

    const ifbFields = [
      'ifb_name',
      'ifb_english_name',
      'ifb_content_language',
      'ifb_social_urls',
      'ifb_number_of_members',
    ]

    // Build hubspot fields
    let fields = []
    let ifbInfo = ''
    for (const [key, value] of formData) {
      if (ifbFields.includes(key)) {
        ifbInfo = ifbInfo += `${key}: ${value}\n`
      } else {
        fields = [
          ...fields,
          {
            objectTypeId: '0-1',
            name: key,
            value: value,
          },
        ]
      }
    }

    const requestData = {
      formId: 'd9ed9784-0cc8-442d-9337-4c04443f2495',
      fields: [
        ...fields,
        {
          objectTypeId: '0-1',
          name: 'ifb_info',
          value: ifbInfo,
        },
      ],
    }

    const request = await sendHubspotForm(requestData)

    if (request.success) {
      setSubmitting(false)
      setSubmitted(true)
    }
  }

  const { main } = props

  return (
    <Card sx={main && styles.card}>
      <Heading
        as={main ? 'h2' : 'h3'}
        sx={{ ...styles.heading, fontSize: main ? 10 : 9 }}
      >
        {props.heading}
      </Heading>
      <Text sx={styles.text}>{props.text}</Text>
      <Box
        as="form"
        id="fan-brigades-form"
        onSubmit={handleSubmit}
        sx={{
          ...styles.form,
          gridTemplateColumns: main ? ['1fr', 'repeat(2, 1fr)'] : '1fr',
        }}
      >
        <Input
          type="text"
          name="firstname"
          placeholder="First name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="lastname"
          placeholder="Last name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="email"
          name="email"
          placeholder="Enter email address"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="ifb_name"
          placeholder="Brigade name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="ifb_english_name"
          placeholder="English name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="ifb_content_language"
          placeholder="Content language"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="ifb_social_urls"
          placeholder="Social media url's"
          required={true}
          sx={styles.input}
        />
        <Input
          type="number"
          name="ifb_number_of_members"
          placeholder="How many members are in your Brigade?"
          required={true}
          min={1}
          sx={styles.input}
        />
        <Text
          as="p"
          sx={{
            ...styles.termsText,
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          We want you to be the first to know about New Signings, Competitions,
          Club News, Ticket Availability and occasional offers from partners.
          You can change your preferences or unsubscribe at any time
        </Text>
        <Wrap
          sx={{
            justifyContent: ['center', null, null, null, null, 'flex-start'],
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          <Label sx={styles.radioLabel}>
            <Radio
              name="opt_in"
              onChange={() => {
                setStayUpdated(true)
              }}
              checked={stayUpdated}
              value="Yes"
              label="Keep me updated"
              sx={styles.radio}
            />
          </Label>
          <Label sx={styles.radioLabel}>
            <Radio
              name="opt_in"
              onChange={() => {
                setStayUpdated(false)
              }}
              value="No"
              checked={!stayUpdated}
              label="No thanks"
              sx={styles.radio}
            />
          </Label>
        </Wrap>
        <Button
          sx={{
            mt: '15px',
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
            maxWidth: main ? '230px' : '100%',
          }}
          type="submit"
          disabled={submitting || submitted}
        >
          {submitting ? 'Submitting' : submitted ? 'Thanks' : 'Submit'}
        </Button>
        <Text
          as="p"
          sx={{
            ...styles.termsText,
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          By clicking ‘Submit’ you agree to our 
          <a href="/policies" target="_blank">
            terms and conditions
          </a>
        </Text>
      </Box>
    </Card>
  )
}

export default FanBrigadesForm
