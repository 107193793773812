/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx, Box, Embed, Text } from 'theme-ui'
import { Overlay, Close } from '../modal'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { releaseBody, lockBody} from '../../utils/helpers'
import Article from '../../components/article'

gsap.registerPlugin(ScrollToPlugin);

const Player = (props) => {

  // const headerContext = useContext(HeaderContext)

  const containerSX = {
    position: 'fixed',
    // top: `${headerContext.height}px`,
    top: '0px',
    left: '0px',
    width: '100%',
    zIndex: 1000,
    height: '100%',
    overflowX: 'auto'
  }

  useEffect(() => {
    // gsap.to(window, {duration: 0.3, scrollTo: 0});
    lockBody()
    return () => {
      releaseBody()
    }
  }, [])

  return (
    <React.Fragment>
      <Overlay />
      <Box as="figure" sx={containerSX}>
        {props.children}
        {props.src &&
          <React.Fragment>
            <Embed src={props.src} allow="autoplay" />
            <Wrapper sx={{marginTop: 10}}>
              <Inner>
                {/* <Text variant='cardCategory' sx={{color: 'primary'}}>Category</Text> */}
                <Text variant="cardTitle" sx={{color: 'white'}}>{props.title}</Text>
                {props.article &&
                    <Article {...props.article} sx={{color: 'white', mt: 4}} />
                }
              </Inner>
            </Wrapper>
          </React.Fragment>
        }
        <Close 
          onClick={props.onClose} 
          url={props.url} 
          linkState={props.linkState} 
        />
      </Box>
    </React.Fragment>
  )
}

export default Player