/** @jsx jsx */
import { jsx, Flex, Card, Text, Button } from 'theme-ui'
// import Background from '../../images/promo-1.png'
// import BackgroundTwo from '../../images/promo-3.png'
// import Shirt from '../../images/shirt.png'
import { responsive } from '../../utils/helpers'
import { renderRichText } from '../../richtext'
import { useMediaQuery } from 'react-responsive'
import { noParagraphTags } from '../../richtext/renderers'

const SmallPromo = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 940px)' })

  const cardSX = {
    position: 'relative',
    height: props.height ? props.height : props.fixedHeight ? responsive('0px', '0px') : responsive('0px', 'auto'),
    paddingBottom: props.height ? '0px' : props.fixedHeight ? responsive('130%', '160%') : responsive('130%', '0px')
  }

  const imageSX = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 8
  }

  const overlaySX = {
    padding: 4,
    flexDirection: 'column',
    position: 'absolute',
    bottom: '0px',
    width: '100%',

    '&:before': {
      content: '""',
      display: props.style === 'Light' ? 'block' : 'none',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(153,153,153,0) 0%,rgba(0,0,0,1) 100%)',
      borderRadius: '0 0 8px 8px',
    }
  }

  const categorySX = {
    position: 'relative',
    color: 'primary'
  }

  const titleSX = {
    position: 'relative',
    color: props.style === 'Light' ? 'white' : 'black',
    marginBottom: 2,
    fontSize: 9,
    lineHeight: 'compact',
    fontFamily: 'light',

    'b': {
      fontFamily: 'headingquiet'
    }
  }

  const buttonSX = {
    position: 'relative',
    alignSelf: 'flex-start'

  }

  const desktopImage = props.imageDesktop?.file?.url
  const mobileImage = props.imageMobile?.file?.url
  const desktopDark = props.imageDesktopDark ? props.imageDesktopDark.file.url : desktopImage
  const mobileDark = props.imageMobileDark ? props.imageMobileDark.file.url : mobileImage
  const imageSrc = isTabletOrMobile ? (props.dark ? mobileDark : mobileImage) : (props.dark ? desktopDark : desktopImage)

  return (
    <Card className={props.className} sx={cardSX} variant="slim">
      <img sx={imageSX} src={imageSrc} alt="" />
      
      <Flex sx={overlaySX}>
        {props.category &&
          <Text sx={categorySX} variant="cardCategory">{props.category}</Text>
        }
        {props.title &&
          <Text sx={titleSX}>{renderRichText(props.title, false, {renderNode: noParagraphTags})}</Text>
        }
        {props.button &&
          <Button as="a" variant={props.button.colour === 'Dark' ? 'tertiary' : props.style === 'Light' ? 'primary' : 'tertiary'} href={props.button.url} sx={buttonSX}>{props.button.label}</Button>
        }
      </Flex>
    </Card>
  )
}

export default SmallPromo