/** @jsx jsx */
import { useState } from 'react'
import { jsx, Card, Heading, Text, Box, Input, Button, Select } from 'theme-ui'
import styles from './contactForm.styles'
import { sendHubspotForm } from '../../utils/hubspot'

const GeneralEnquiriesForm = props => {
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const enquiryTypeOptions = ['Ticketing', 'Press', 'Stadium', 'Parking']

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const enquiresForm = document.getElementById('enquires-form')
    const formData = new FormData(enquiresForm)

    // Build hubspot fields
    let fields = []
    for (const [key, value] of formData) {
      if (key === 'enquiry-type') {
        fields = [
          ...fields,
          {
            objectTypeId: '0-5',
            name: 'hs_ticket_category',
            value: value,
          },
        ]
      } else {
        fields = [
          ...fields,
          {
            objectTypeId: '0-1',
            name: key,
            value: value,
          },
        ]
      }
    }

    const requestData = {
      formId: '617edf58-1dae-4b4b-878f-bfe9a112a2e3',
      fields: [
        ...fields,
        {
          objectTypeId: '0-5',
          name: 'subject',
          value: `General enquiry form - ${formData.get('email')}`,
        },
        {
          objectTypeId: '0-5',
          name: 'content',
          value: `First name: ${formData.get(
            'firstname'
          )}\nLast name: ${formData.get('firstname')}\nEmail: ${formData.get(
            'email'
          )}\nEnquiry Type: ${formData.get('enquiry-type')}`,
        },
      ],
    }

    const request = await sendHubspotForm(requestData)

    if (request.success) {
      setSubmitting(false)
      setSubmitted(true)
    }
  }

  const { main } = props

  return (
    <Card sx={main && styles.card}>
      <Heading
        as={main ? 'h2' : 'h3'}
        sx={{ ...styles.heading, fontSize: main ? 10 : 9 }}
      >
        {props.heading}
      </Heading>
      <Text sx={styles.text}>{props.text}</Text>
      <Box
        as="form"
        id="enquires-form"
        onSubmit={handleSubmit}
        sx={{
          ...styles.form,
          gridTemplateColumns: main ? ['1fr', 'repeat(2, 1fr)'] : '1fr',
        }}
      >
        <Input
          type="text"
          name="firstname"
          placeholder="First name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="text"
          name="lastname"
          placeholder="Last name"
          required={true}
          sx={styles.input}
        />
        <Input
          type="email"
          name="email"
          placeholder="Enter email address"
          required={true}
          sx={styles.input}
        />
        <Input
          type="tel"
          name="phone"
          placeholder="Phone number"
          required={true}
          sx={styles.input}
        />
        <Box>
          <Select sx={styles.input} name="enquiry-type" required={true}>
            <option value="">Select enquiry type</option>
            {enquiryTypeOptions.map((item, i) => (
              <option key={i}>{item}</option>
            ))}
          </Select>
        </Box>
        <Button
          sx={{
            mt: '15px',
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
            maxWidth: main ? '230px' : '100%',
          }}
          type="submit"
          disabled={submitting || submitted}
        >
          {submitting ? 'Submitting' : submitted ? 'Thanks' : 'Submit'}
        </Button>
        <Text
          as="p"
          sx={{
            ...styles.termsText,
            gridColumn: main ? ['1 / 2', '1 / 3'] : '1 / 2',
          }}
        >
          By clicking ‘Submit’ you agree to our 
          <a href="/policies" target="_blank">
            terms and conditions
          </a>
        </Text>
      </Box>
    </Card>
  )
}

export default GeneralEnquiriesForm
