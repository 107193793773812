const style = {
  card: {
    position: 'relative',
  },
  kitContainer: {
    width: '100%',
    textAlign: 'center',
  },
  image: {
    width: '300px',
    margin: '0 auto'
  },
  overlay: {
    padding: 4,
    flexDirection: 'column',
    position: 'absolute',
    bottom: '0px',
    width: '100%',

    '&:before': {
      content: '""',
      display: 'none',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(153,153,153,0) 0%,rgba(0,0,0,1) 100%)',
      borderRadius: '0 0 8px 8px',
    }
  },
  category: {
    position: 'relative',
    color: 'primary'
  },
  title: {
    position: 'relative',
    color: 'black',
    marginBottom: 2,

    'strong': {
      fontWeight: 700
    }
  },
  button: {
    position: 'relative',
    alignSelf: 'flex-start'
  }
}

export default style