/** @jsx jsx */
import { jsx, Box, Button } from 'theme-ui'
import { useEffect, useState } from 'react'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import SectionHeader from '../sectionHeader'
import { CaptionCard } from '../card'
import Masonry from 'react-masonry-css'
import TwitterIcon from '../../images/twitter.png'
import TwitterAPI from '../../api_aws/twitter'

const Twitter = () => {
  const wrapperSX = {
    marginBottom: 17,
  }

  const cardSX = {
    marginBottom: 5,
  }

  const masonrySX = {
    marginBottom: 9,

    '.my-masonry-grid': {
      display: 'flex',
      marginLeft: -5,
      width: 'auto',
    },

    '.my-masonry-grid_column': {
      paddingLeft: 5,
      backgroundClip: 'padding-box',
    },
  }

  const iconSX = {
    width: '30px',
    height: '30px',
    marginBottom: 4,
  }

  const buttonSX = {
    margin: '0px auto',
  }

  const [tweets, setTweets] = useState()

  useEffect(() => {
    ;(async () => {
      const twitter = new TwitterAPI()
      const tweets = await twitter.getTweets()
      if (!tweets.success) return
      setTweets(tweets.data.filter((_, i) => i <= 6))
    })()
  }, [])

  const Icon = () => {
    return <img sx={iconSX} src={TwitterIcon} alt="Twitter" />
  }

  return (
    <Wrapper sx={wrapperSX}>
      <Inner>
        <SectionHeader title="#WeAreFGR" />
        <Box sx={masonrySX}>
          <Masonry
            breakpointCols={{
              default: 3,
              700: 2,
              500: 1,
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {tweets?.map((x, i) => {
              return (
                <CaptionCard
                  key={x.id}
                  sx={cardSX}
                  hideOverlay
                  icon={<Icon />}
                  excerpt={x.full_text}
                  thumbnail={
                    x.entities?.media && x.entities.media.length > 0
                      ? { url: x.entities.media[0].media_url_https }
                      : false
                  }
                  date={x.created_at}
                  url={`https://twitter.com/FGRFC_Official/status/${x.id_str}`}
                  target="_blank"
                />
              )
            })}
          </Masonry>
        </Box>
        <Button
          pl="15px"
          as="a"
          sx={buttonSX}
          variant="iconText"
          target="_blank"
          href="http://twitter.com/FGRFC_Official"
        >
          <Icon />
          Follow
        </Button>
      </Inner>
    </Wrapper>
  )
}

export default Twitter
