import axios from 'axios'
import { getAmplifyEnv } from '../config'

class Twitter {

  constructor() {
    const awsconfig = getAmplifyEnv();
    const fgrApiEndpoint = awsconfig?.aws_cloud_logic_custom.find(
      ({ name } = {}) => name === 'fgrApi'
    ).endpoint
    this.base = fgrApiEndpoint ? `${fgrApiEndpoint}/feed` : 'https://5sc7c4uj3m.execute-api.eu-west-1.amazonaws.com/ecodev/feed'
  }

  getTweets = async () => {
    const query = `/tweets`;
    const cache = this.getCache(query);
    return cache ? cache : await this.query(query)
  }

  getCache = (key) => {
    const cached = localStorage.getItem(key)
    if (!cached) return false
    const item = JSON.parse(cached)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return false
    }
    return {success: true, data: item.value}
  }

  setCache = (key, value, ttl) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  query(url) {
    const _this = this;
    return axios({
      url: `${this.base}${url}`,
      method: 'get',
    }).then((res) => {
      // Set in cache for 24 hours
      _this.setCache(url, res.data, 86400000)
      return {success: true, data: res.data}
    }).catch((err) => {
      return {success: false, error: err.data}
    });
  }
}

export default Twitter