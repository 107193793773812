/** @jsx jsx */
import { jsx, Flex, Box, Text } from 'theme-ui';
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { responsive } from '../../utils/helpers';
import { Link } from 'gatsby'
import Arrow from '../../icons/arrow-back.svg'

const Byline = (props) => {

  const containerSX = {
    backgroundColor: 'white',
    paddingTop: 3,
    paddingBottom: 3,
    borderBottom: '5px solid',
    borderColor: 'greyborderlight',
    mb: 8,
  }

  const layoutSX = {
    justifyContent: 'space-between',
    flexDirection: props.sameLine ? 'row' : responsive('column', 'row'),
  }

  const tagSX = {
    color: 'black',
    textDecoration: 'none',
    display: 'flex'
  }

  const Left = () => {
    const Tag = props.previous ? Link : Text
    return (
      <Tag  
        sx={{...tagSX, mb: props.sameLine ? 0 : responsive(2, 0)}} 
        to={props.previous ? props.previous : '/'}
      >
        {props.previous &&
          <Box sx={{mr: 2}}><Arrow /></Box>
        }
        {props.left}
      </Tag>
    )
  }

  const Right = () => {
    const Tag = props.next ? Link : Text
    return (
      <Tag 
        to={props.next} 
        sx={tagSX}
      >
        {props.right}
        {props.next &&
          <Box sx={{ml: 2}}><Arrow sx={{transform: 'rotate(180deg)'}} /></Box>
        }
      </Tag>
    )
  }

  return (
    <Box sx={containerSX}>
      <Wrapper>
        <Inner>
          <Flex sx={layoutSX}>
            <Left />
            <Right />
          </Flex>
        </Inner>
      </Wrapper>
    </Box>
  )
}

export default Byline