/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui';
import React, { useState } from 'react'
import Carousel from '../carousel';
import { ImageCard } from '../card'
import SectionHeader from '../sectionHeader'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import style from './style'
import { Inline } from 'raam'
import Divider from '../divider'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { responsive } from '../../utils/helpers'

const Gallery = (props) => {

  const [index, setIndex] = useState(1)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 939px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 940px)' })

  const handleAfterChange = (index) => {
    setIndex(index+1)
  }

  const ViewAll = () => {
    return (
      <Inline sx={style.inline}>
        <Link to={`/gallery/`} sx={style.bold}>View all</Link>
        <Divider height={'46px'} />
        <Text><Text as="strong" sx={style.bold}>Image {index}</Text> of {props.items.length}</Text>
      </Inline>
    )
  }
  
  return (
    <React.Fragment>
      {props.showHeader &&
        <Wrapper>
          <Inner>
            <SectionHeader 
              title='Photo Gallery' 
              button={{url: `/gallery/${props.slug}`, label: props.title}} 
              aside={
                isDesktop 
                  ? <ViewAll />
                  : false
              }
            />
          </Inner>
        </Wrapper>
      }
      
      <Carousel 
        settings={{
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '23%',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                centerPadding: '50px'
              }
            }
          ],
          dots: false,
          arrows: true,
          afterChange: handleAfterChange
        }} 
        sx={style.carousel}
      >
        {props.items.map((x,i) => {
          return (
            <Box p={3} key={i}>
              <ImageCard 
                thumbnail={{url: x.image?.file?.url}} 
                showOverlay title={x.title} 
                category={{name: x.tagline, flourish: true}} 
                aspectRatio={
                  props.aspectRatio 
                    ? props.aspectRatio 
                    : isTabletOrMobile
                      ? 1/1
                      : 16/9
                  }
              />
            </Box>
          )
        })}
      </Carousel>
      {isTabletOrMobile &&
        <Wrapper>
          <Inner>
            <Box mb={7}>
              <ViewAll />
            </Box>
          </Inner>
        </Wrapper>
      }
    </React.Fragment>
  )
}

export default Gallery