/** @jsx jsx */
import { jsx, Button } from 'theme-ui'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { Cross } from '../shapes'
import { Link } from 'gatsby'

const Close = (props) => {

  const actionsSX = {
    position: 'absolute',
    top: 5,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1001,
  }

  const actionsInnerSX = {
    display: 'flex',
    justifyContent: 'flex-end'
  }

  const closeSX = {
    padding: 4,
    backgroundColor: 'white',
    position: 'relative'
  }

  const iconSX = {
    position: 'absolute', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)'
  }

  return (
    <Wrapper 
      sx={actionsSX} 
      as={
        props.url 
          ? Link 
          : false} 
      to={props.url} 
      state={props.linkState}>
      <Inner sx={actionsInnerSX}>
        <Button variant="icon" sx={closeSX} onClick={props.onClick}>
          <Cross width={13} height={13} sx={iconSX} />
        </Button>
      </Inner>
    </Wrapper>
  )
}

export default Close