/** @jsx jsx */
import { useEffect, useState } from 'react';
import { jsx, Flex, Text, Grid, Button } from 'theme-ui';
import { ImageCard } from '../card'
import { responsive } from '../../utils/helpers'
import { renderRichText } from '../../richtext'
import { useMediaQuery } from 'react-responsive'
import { noParagraphTags } from '../../richtext/renderers'

const ShopPromo = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 940px)' })
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }
  
  const wrapperSX = {
    width: '100%',
    height: responsive('auto', '570px'),
    position: 'relative',
    marginTop: 17,
    marginBottom: 17,
    borderRadius: 8,
    overflow: 'hidden',
    flexDirection: responsive('column', 'row'),
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  const backgroundSX = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: responsive('auto', '100%'),
    objectFit: responsive('contain', 'cover'),
    objectPosition: 'left top',
  }

  const captionSX = {
    width: responsive('100%', '22%'),
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    padding: 2,
    pr: responsive(2, 5),
    flexShrink: 0
  }

  const gridSX = {
    width: responsive('100%', '75%'),
    flexShrink: 0,
    padding: 3,
  }

  const textSX = {
    color: 'black',
    fontSize: 10,
    lineHeight: 'compact',
    fontFamily: 'light',

    'b': {
      fontFamily: 'heading'
    }
  }

  const desktopImage = props.imageDesktop?.file?.url
  const mobileImage = props.imageMobile?.file?.url
  const desktopDark = props.imageDesktopDark ? props.imageDesktopDark.file.url : desktopImage
  const mobileDark = props.imageMobileDark ? props.imageMobileDark.file.url : mobileImage
  const imageSrc = isTabletOrMobile ? (props.dark ? mobileDark : mobileImage) : (props.dark ? desktopDark : desktopImage)

  return (
    <Flex sx={wrapperSX}>
      <img sx={backgroundSX} src={imageSrc} alt="" />
      <Flex sx={captionSX}>
        {props.title &&
          <Text sx={textSX}>{renderRichText(props.title, false, {renderNode: noParagraphTags})}</Text>
        }
        {props.button &&
          <Button variant={props.button.colour === 'Dark' ? 'tertiary' : props.style === 'Light' ? 'primary' : 'tertiary'} as="a" url={props.button.url}>{props.button.label}</Button>
        }
      </Flex>
      {props.products &&
        <Grid sx={gridSX} columns={responsive(1, 3)} gap={5}>
          {props.products.map((x,i) => {
            if (isTabletOrMobile && (i === 1 || i === 2))
              return null
            return <ImageCard 
              key={i}
              thumbnail={{url: x.image?.file?.url}}
              aspectRatio={288/504} 
              showOverlay 
              title={x.title}
              category={{name: 'FGR Shop'}} 
              button={{label: 'shop', url: x.url}} 
            />  
          })}
        </Grid>
      }
      
    </Flex>
  )
}

export default ShopPromo