/** @jsx jsx */
import React from 'react'
import { jsx, Grid, Flex, Text } from 'theme-ui';
import { responsive } from '../../utils/helpers';
import Logo from '../versus/logo'

const LineUp = (props) => {

  const breakpoint = 2

  const emblem = {
    width: '58px',
    height: '58px',
    marginBottom: 3
  }

  const layoutSX = {
    flexDirection: 'column',
    flexWrap: 'wrap',
  }

  const listOneSX = {
    ...layoutSX,
    alignItems: 'flex-end'
  }

  const listTwoSX = {
    ...layoutSX,
    alignItems: 'flex-start'
  }

  const textSX = {
    flexWrap: 'wrap',
    lineHeight: 'body',
    // fontSize: 0
  }

  const reverseSX = {
    ...textSX,
    alignItems: 'left',
    flexDirection: 'row-reverse',
    textAlign: 'right'
  }

  const boldSX = {
    fontWeight: 700,
    width: responsive('100%', 'auto', breakpoint)
  }

  const Coach = ({name, index}) => {
    return (
      <Flex sx={index === 0 ? reverseSX : textSX} mb={5}>
        <Player title='Coach' name={name} index={index} />
      </Flex>
    )
  }

  const Player = ({title, name, index}) => {
    return (
      <React.Fragment>
        <Text sx={boldSX} pr={index === 0 ? 0 : 1} pl={index === 0 ? 1 : 0}>{title}</Text>
        {title === 'Coach' &&
          <Text>{name}</Text>
        }
      </React.Fragment>
    )
  }

  const Subs = ({subs, index}) => {
    if (!subs)
      return null

    return (
      <React.Fragment>
        <Text pt={2} mb={1} sx={boldSX}>Substitutes</Text>
        <Flex sx={index === 0 ? reverseSX : textSX} mb={1}>
          {subs.map((x,i) => {
            return (
              <Text mr={'5px'} key={i}>{x}{((index === 0 && i === 0) || (index === 1 && i === subs.length - 1)) ? '' : ', '}</Text>
            )
          })}
        </Flex>
      </React.Fragment>
    )
  }

  const Team = ({index}) => {
    const location = index === 0 ? 'One' : 'Two'
    const club = props[`club${location}`]
    const goalkeeper = props[`team${location}Goalkeeper`]
    const defenders = props[`team${location}Defenders`]
    const midfielders = props[`team${location}Midfielders`]
    const forward = props[`team${location}Forward`]
    const substitutes = props[`team${location}Substitutes`]
    const logo = club?.crest?.file?.url
    return (
      <React.Fragment>
        {logo &&
          <img sx={emblem} src={logo} alt="" />
        }
        {!logo &&
          <Logo sx={emblem} name={club?.name} />
        }
        <Coach name={props[`team${location}Coach`]} index={index} />
        {goalkeeper &&
          <Flex sx={index === 0 ? reverseSX : textSX} mb={1}>
            <Player title={goalkeeper} name='Goalkeeper' index={index} />
          </Flex>
        }
        {defenders?.map((x, i) => {
          return (
            <Flex sx={index === 0 ? reverseSX : textSX} key={i} mb={1}>
              <Player title={x} name='Defender' index={index} />
            </Flex>
          )
        })}
        {midfielders?.map((x, i) => {
          return (
            <Flex sx={index === 0 ? reverseSX : textSX} key={i} mb={1}>
              <Player title={x} name='Midfielder' index={index} />
            </Flex>
          )
        })}
        {forward?.map((x, i) => {
          return (
            <Flex sx={index === 0 ? reverseSX : textSX} key={i} mb={1}>
              <Player title={x} name='Forward' index={index} />
            </Flex>
          )
        })}
        <Subs subs={substitutes} index={index} />
      </React.Fragment>
    )
  }

  return (
    <Grid columns={2}>
      <Flex sx={listOneSX}>
        <Team index={0} />
      </Flex>
      <Flex sx={listTwoSX}>
        <Team index={1} />
      </Flex>
    </Grid>
  )
}

export default LineUp