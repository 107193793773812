import React from 'react'
import SmallPromo from './small'
import LargePromo from './large'
import ShopPromo from './shop'

const Promo = (props) => {

  const { data } = props

  const promoData = {
    imageDesktop: data.desktopImage,
    imageMobile: data.mobileImage,
    imageDesktopDark: data.desktopImageDarkMode,
    imageMobileDark: data.mobileImageDarkMode,
    title: data.heading,
    category: data.category,
    style: data.style,
    button: data.buttonLabel && data.buttonUrl ? {label: data.buttonLabel, url: data.buttonUrl, variant:data.buttonStyle, colour: data.buttonColour} : false,
    dark: props.dark
  }
  switch (data.variant) {
    case 'Large':
      return (
        <LargePromo {...promoData} />
      )
    case 'Small':
      return (
        <SmallPromo {...promoData} fixedHeight /> 
      )
    case 'Products':
      return (
        <ShopPromo 
          {...promoData}
          products={data.products}
        />
      )
    default:
      return null
  }
}

export default Promo