/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';

const Embellishment = (props) => {

  const details = props.image?.file?.details?.image;
  const width = details?.width;
  const height = details?.height;

  const containerSX = {
    justifyContent: 'center',
    py: 5,
  }

  const imageSX = {
    width: width ? width / 2 : 'auto', 
    height: height ? height / 2 : 'auto'
  }

  return (
    <Flex sx={containerSX}>
      <img 
        src={props.image?.file?.url} 
        alt={props.title} 
        sx={imageSX} 
      />
    </Flex>
  )
}

export default Embellishment