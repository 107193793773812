/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { Triangle } from '../shapes'

const PlayButton = (props) => {

  const { 
    arrowWidth, 
    arrowHeight, 
    arrowColor,
    width,
    height
  } = props

  const sx = {
    backgroundColor: 'primary',
    alignItems: 'center',
    justifyContent: 'center',
    width: width ? width : '60px',
    height: height ? height : '60px',
    borderRadius: 2,
    cursor: 'pointer',
    transition: 'all 0.2s linear',
    display: 'flex',

    '&:hover': {
      backgroundColor: 'white'
    }
  }

  return (
    <Flex 
      as={props.as} 
      sx={sx} 
      className={props.className} 
      onClick={props.onClick} 
      state={props.state}
      to={props.to}
    >
      <Triangle 
        width={arrowWidth ? arrowWidth : 13} 
        height={arrowHeight ? arrowHeight : 19} 
        colour={arrowColor ? arrowColor : 'black'} />
    </Flex>
  )
}

export default PlayButton