/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui';
import Single from './single'

const Statistic = (props) => {

  const parseLabel = (value) => {
    return value
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function(str){ return str.toUpperCase(); })
  }

  const parseValue = (value, name) => {
    return {amount: value, label: name === 'Possession' ? `${value}%` : value}
  }

  const Item = ({name}) => {
    const teamOneValue = props[`teamOne${name}`]
    const teamTwoValue = props[`teamTwo${name}`]

    if (!teamOneValue || !teamTwoValue)
      return null

    return <Single 
      heading={parseLabel(name)}
      left={parseValue(teamOneValue, name)}
      right={parseValue(teamTwoValue, name)}
    />
  }

  return (
    <React.Fragment>
      <Item name="Possession" />
      <Item name="Shots" />
      <Item name="ShotsOnTarget" />
      <Item name="Corners" />
      <Item name="Tackles" />
    </React.Fragment>
  )
}

export default Statistic