/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

const Table = (props) => {

  const containerSX = {
    width: '100%',
    overflowX: 'auto'
  }

  const tableSX = {
    width: '100%',
    minWidth: '500px',
  }

  const headingSX = {
    backgroundColor: 'primary',
    padding: 4,
    fontWeight: 700
  }

  const bodySX = {
    padding: 4,
    borderBottom: '1px solid',
    borderColor: 'background',
  }

  const bodyRowSX = {

    '&:last-child td': {
      border: 'none'
    }
  }

  const Headings = () => {
    if (!props.headings || !Array.isArray(props.headings))
      return null

    return props.headings.map((x,i) => {
      return (
        <td key={i} sx={headingSX}>
          {x}
        </td>
      )
    })
  }

  const Body = () => {
    if (!props.body || !Array.isArray(props.body))
      return null

    return props.body.map((row, i) => {
      return (
        <tr key={i} sx={bodyRowSX}>
          {row.map((x, i) => {
            return (
              <td key={i} sx={bodySX}>
                {x}
              </td>
            )
          })}
        </tr>
      )
    })
  }

  return (
    <Box sx={containerSX}>
      <table sx={tableSX} className={props.className}>
        <thead>
          <tr>
            <Headings />
          </tr>
        </thead>
        <tbody>
          <Body/>
        </tbody>
      </table>
    </Box>
  )
}

export const parseTableData = (data) => {
  if (!data || !Array.isArray(data))
    return false
  return {
    headings: data[0],
    body: data.filter((_,i) => i !== 0)
  }
}

export default Table