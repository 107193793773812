/** @jsx jsx */
import { jsx, Flex, Card, Text, Button, Box } from 'theme-ui'
import { responsive } from '../../utils/helpers'
import { Link } from "gatsby"
import { getUser } from '../../utils/auth'
import { Kit } from '../registration'
import style from './shirt.style'
import useCustomShirt from '../../hooks/useCustomShirt'

const ShirtPromo = (props) => {

  

  const user = getUser();
  const { product, customShirtPromoText } = useCustomShirt();

  if (!user) {
    return null
  }
  
  

  const getBackgroundColor = () => {
    switch (user?.attributes['custom:kitType']) {
      case 'home':
      default:
        return 'primarydark'
      case 'away':
        return 'away'
      case 'third':
        return 'third'
      case 'goalkeeper':
        return 'goalkeeper'
    }
  }

  return (
    <Card 
      sx={{
        ...style.card,
        backgroundColor: getBackgroundColor(),
        height: props.height ? props.height : props.fixedHeight ? responsive('0px', '0px') : responsive('0px', 'auto'),
        paddingBottom: props.height ? '0px' : props.fixedHeight ? responsive('130%', '160%') : responsive('130%', '0px')
      }} 
      className={props.className} 
      variant="slim"
    >
      <Box sx={style.kitContainer}>
        <Kit 
          sx={style.image}
          type={user?.attributes['custom:kitType']}
          name={user?.attributes['family_name']} 
          number={user?.attributes['custom:kitNumber']} 
        />
      </Box>
      <Flex sx={style.overlay}>
        {props.type === 'shop' &&
          <Text sx={style.category} variant="cardCategory">FGR Shop</Text>
        }
        
        <Text 
          variant="cardTitle" 
          sx={style.title}
        >
          {customShirtPromoText}
        </Text>
        <Button 
          as={Link} 
          to={`/shop/${product?.node?.product?.handle}`} 
          variant={'tertiary'} 
          sx={style.button}>
            Buy
        </Button>
      </Flex>
    </Card>
  )
}

export default ShirtPromo