/** @jsx jsx */
import { jsx, Text, Box } from 'theme-ui';
import { Wrap } from 'raam';
import Divider from '../divider'
import Arrow from '../../images/arrow-full.svg'
import { Link } from 'gatsby'

const SectionHeader = (props) => {

  const wrapperSX = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    borderBottom: props.variant === 'underline' ? '1px solid' : '',
    borderColor: 'black',
    mb: props.variant === 'underline' ? 4 : '0px'
  }

  const copySX = {
    // marginBottom: 2,
    overflow: 'visible',
  }

  const iconSX = {
    width: '64px',
    height: '42px',
    position: 'absolute',
    right: '0px',
    top: '-5px',

    '> img': {
      width: '64px',
      height: '42px',
    }
  }

  const titleSX = {
    fontSize: props.variant === 'underline' ? 6 : 8, 
    fontFamily: props.variant === 'underline' ? 'body' : 'heading',
    textTransform: props.variant === 'underline' ? 'uppercase' : 'none',
    fontWeight: props.variant === 'underline' ? 700 : 400
  }

  const buttonSX = {
    color: 'black', 
    textDecoration: 'none',
  }

  return (
    <Box className={props.className} sx={wrapperSX}>
      <Wrap gap={5} sx={copySX}>
        <Text sx={titleSX}>{props.title}</Text>
        {props.button &&
          <Divider height={'46px'} />
        }
        {props.button &&
          <Link sx={buttonSX} to={props.button.url}>{props.button.label}</Link>
        }
      </Wrap>
      {props.aside}
      {props.icon &&
        <a href={props.icon.url} aria-label='View more' sx={iconSX}><img src={Arrow} alt="" /></a>
      }
    </Box>
  )
}

export default SectionHeader