/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import QuoteMark from '../../images/quote.svg'

const Main = (props) => {

  const containerSX = {
    
    'p': {
      lineHeight: 'body',
      marginBottom: 5
    },

    'blockquote': {
      marginBottom: 5,
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontSize: '56px',

      '&:before, &:after': {
        content: '""',
        width: '42px',
        height: '24px',
        display: 'block',
        background: `url(${QuoteMark}) left top / 41px 24px no-repeat transparent`
      },

      '&:before': {
        marginBottom: '15px',
      },

      '&:after': {
        marginTop: '15px',
        transform: 'rotate(180deg)'
      }
    },

    'h3': {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontSize: 8,
    },

    '> img': {
      marginBottom: 2,
      width: '100%'
    }
  }

  return (
    <Box as="section" sx={containerSX} className={props.className} ref={props.forwardedRef}>
      {props.children}
    </Box>
  )
}

export default Main