/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ImageCard } from '../card'
import { PlayButton } from '../video'

const VideoCard = (props) => {
  
  return (
    <ImageCard 
      {...props} 
      meta={props.date ? {date: props.date} : ''} 
      icon={<PlayButton />} 
      url={`/news/?video=${props.slug}`}
      sx={{display: 'block'}}
    />
  )
}

export default VideoCard