// recursively get the text from all child nodes
export const toString = (node) => {
  if ('content' in node) {
    return (node.content).reduce((copy, child) => {
      const recurse = toString(child) || ''
      const value = 'value' in child ? child.value : ''
      return copy + recurse + value
    }, '')
  } else return ''
}

// Checks whether this node has ContentfulCard references
export const hasCards = (node) => {
  return node?.references?.some((x) => {
    return x.__typename === 'ContentfulCard'
  })
}